import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';

import Card from '../../../components/Card';
import DraggableArea from '../../../components/DraggableArea';
import DraggableItem from '../../../components/DraggableItem';
import ConfirmModal from '../../modals/ConfirmModal';
import EbookBundleSectionItem from '../components/EbookBundleSectionItem';
import EbookSectionItem from '../components/EbookSectionItem';
import EditorReviewSectionItem from '../components/EditorReviewSectionItem';
import FeedSectionItem from '../components/FeedSectionItem';
import LinkSectionItem from '../components/LinkSectionItem';
import ProductSetSectionItem from '../components/ProductSetSectionItem';
import QueryEbookBundleSectionItem from '../components/QueryEbookBundleSectionItem';
import QueryEbookSectionItem from '../components/QueryEbookSectionItem';
import QueryProductSetSectionItem from '../components/QueryProductSetSectionItem';
import SearchSectionItem from '../components/SearchSectionItem';
import SectionItems from '../components/SectionItems';
import { ADD_ITEM_OPTION_BY_LAYOUT_ID, IConfigItemType, ISectionLayoutId } from '../validates/sectionBuilderSchema';
// import ExploreSectionItem from '../components/ExploreSectionItem';
// import ProductSetSectionItem from '../components/ProductSetSectionItem';
// import QueryProductSetSectionItem from '../components/QueryProductSetSectionItem';
// import UserSectionItem from '../components/UserSectionItem';

const AddItemInSectionPresenter = () => {
  const { register, control, watch, setValue } = useFormContext();
  const [layoutId, configItems] = watch(['layoutId', 'configItems']);
  const { fields, append, swap } = useFieldArray({
    control,
    name: 'configItems',
  });

  // local state
  const [activeMultipleSelection, setActiveMultipleSelection] = useState<boolean>(false);
  const [deleteIndexSet, setDeleteIndexSet] = useState<Set<string | number | undefined>>(new Set());
  const [deleteSingleItem, setDeleteSingleItem] = useState<number | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const onSelectItem = useCallback(
    (deleteIndex?: string | number) => {
      if (deleteIndexSet.has(deleteIndex)) setDeleteIndexSet((prv) => new Set([...prv].filter((target) => target != deleteIndex)));
      else {
        setDeleteIndexSet((prv) => new Set([...prv, deleteIndex]));
      }
    },
    [deleteIndexSet],
  );

  const isSelectItemForDelete = useCallback(
    (targetIndex: string | number) => {
      return deleteIndexSet.has(targetIndex);
    },
    [deleteIndexSet],
  );

  const getSectionNamesByLayout = useCallback((layoutId: ISectionLayoutId) => {
    switch (layoutId) {
      case ISectionLayoutId.BookCover:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.bookCover;
      case ISectionLayoutId.MainBanner:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.mainBanner;
      case ISectionLayoutId.TagCloud:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.tagCloud;
      case ISectionLayoutId.BookBanner:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.bookBanner;
      case ISectionLayoutId.Rank:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.rank;
      case ISectionLayoutId.BookCover2:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.bookCover2;
      case ISectionLayoutId.EditorPick:
        return ADD_ITEM_OPTION_BY_LAYOUT_ID.editorPick;
    }
  }, []);

  const onAddSectionItem = useCallback(
    (itemType: IConfigItemType) => {
      append({ type: itemType });
    },
    [append],
  );

  const onDeleteSectionItem = useCallback((deleteIndex?: number) => {
    setShowConfirmModal(true);
    if (typeof deleteIndex === 'number') {
      setDeleteSingleItem(deleteIndex);
    }
  }, []);

  const onDeleteSingleConfigItem = useCallback(
    (deleteIndex?: number) => {
      setValue(
        'configItems',
        configItems.filter((_: unknown, index: string | undefined) => index !== deleteIndex),
      );
    },
    [configItems, setValue],
  );

  const onDeleteMultipleConfigItems = useCallback(() => {
    setValue(
      'configItems',
      configItems.filter((_: unknown, index: string | undefined) => ![...deleteIndexSet].includes(index)),
    );
    setDeleteIndexSet(new Set());
  }, [deleteIndexSet, configItems, setValue]);

  const onDragItem = useCallback((dragIndex: number, hoverIndex: number) => swap(dragIndex, hoverIndex), [swap]);

  const onCreateNewSectionItemByName = useCallback(
    (props: { index: number; sectionName?: string }) => {
      const { index, sectionName } = props;
      switch (sectionName) {
        case IConfigItemType.Ebook:
          return <EbookSectionItem index={index} itemData={configItems[index]} register={register} onDeleteSectionItem={onDeleteSectionItem} />;
        case IConfigItemType.EbookBundle:
          return <EbookBundleSectionItem index={index} itemData={configItems[index]} register={register} onDeleteSectionItem={onDeleteSectionItem} />;
        case IConfigItemType.Feed:
          return <FeedSectionItem index={index} itemData={configItems[index]} register={register} />;
        case IConfigItemType.Link:
          return <LinkSectionItem index={index} itemData={configItems[index]} register={register} />;
        case IConfigItemType.QueryEbook:
          return <QueryEbookSectionItem control={control} index={index} itemData={configItems[index]} register={register} />;
        case IConfigItemType.QueryEbookBundle:
          return <QueryEbookBundleSectionItem control={control} index={index} itemData={configItems[index]} register={register} />;
        case IConfigItemType.EditorReviewEbook:
          return <EditorReviewSectionItem index={index} itemData={configItems[index]} register={register} />;
        case IConfigItemType.Search:
          return <SearchSectionItem index={index} itemData={configItems[index]} register={register} />;
        // case IConfigItemType.Explore:
        //   return (
        //     <ExploreSectionItem
        //       isSelectItem={isSelectItem}
        //       onSelectItem={() => onSelectItem(index)}
        //       index={index}
        //       itemData={configItems[index]}
        //       register={register}
        //       onDeleteSectionItem={onDeleteSectionItem}
        //     />
        //   );
        case IConfigItemType.ProductSet:
          return <ProductSetSectionItem index={index} itemData={configItems[index]} register={register} />;
        case IConfigItemType.QueryProductSet:
          return <QueryProductSetSectionItem index={index} itemData={configItems[index]} register={register} control={control} />;
        // case IConfigItemType.User:
        //   return <UserSectionItem isSelectItem={isSelectItem} onSelectItem={() => onSelectItem(index)} index={index} itemData={configItems[index]} register={register} onDeleteSectionItem={onDeleteSectionItem} />;
      }
    },
    [configItems, register, onDeleteSectionItem, control],
  );

  const onRenderDraggableItem = useCallback(
    (draggableItem: { id: string; type?: IConfigItemType }, index: number) => {
      const uniqueId = draggableItem.id;
      return (
        <DraggableItem
          key={uniqueId}
          index={index}
          id={uniqueId}
          onDragItem={onDragItem}
          canDrag={!deleteIndexSet.size}
          className={classNames('!rounded-[4px] min-h-[80px] !mb-0 !border-b-0 last:!border-b-[1px] !bg-componentsBgGrouped02', {
            '!border-solid !cursor-default': deleteIndexSet.size,
          })}
        >
          <SectionItems
            activeMultipleSelection={activeMultipleSelection}
            isSelectItemForDelete={!!isSelectItemForDelete(index)}
            onSelectItem={() => onSelectItem(index)}
            onDeleteSectionItem={() => onDeleteSectionItem?.(index)}
          >
            <div className="w-full">{onCreateNewSectionItemByName({ index, sectionName: draggableItem.type })}</div>
          </SectionItems>
        </DraggableItem>
      );
    },
    [activeMultipleSelection, deleteIndexSet.size, isSelectItemForDelete, onCreateNewSectionItemByName, onDeleteSectionItem, onDragItem, onSelectItem],
  );

  // const configItemsToDeleteInModal = useMemo(() => {
  //   if (typeof deleteSingleItem === 'number') {
  //     return configItems?.find((_: unknown, index: number) => index === deleteSingleItem);
  //   }

  //   return configItems?.filter((_: unknown, index: string | undefined) => [...deleteIndexSet].includes(index));
  // }, [deleteIndexSet, configItems, deleteSingleItem]);

  const configItemsToDeleteInModal = useMemo(() => {
    if (typeof deleteSingleItem === 'number') {
      return `amount config items to delete: 1`;
    }

    return `amount config items to delete: ${deleteIndexSet.size}`;
  }, [deleteIndexSet, deleteSingleItem]);

  const deleteItemFromSelectedList = useCallback(() => {
    if (typeof deleteSingleItem === 'number') {
      onDeleteSingleConfigItem(deleteSingleItem);
      setDeleteSingleItem(null);
      return;
    }

    if (deleteIndexSet.size) {
      onDeleteMultipleConfigItems();
      return;
    }
  }, [deleteIndexSet.size, deleteSingleItem, onDeleteSingleConfigItem, onDeleteMultipleConfigItems]);

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          onClose={() => {
            setDeleteIndexSet(new Set());
            setDeleteSingleItem(null);
            setShowConfirmModal(false);
          }}
          onConFirm={() => {
            deleteItemFromSelectedList();
            setActiveMultipleSelection(false);
            setShowConfirmModal(false);
          }}
          title="Remove Section"
          subTitle="Confirm to Remove section"
          // messages={[JSON.stringify(configItemsToDeleteInModal)]}
          messages={[configItemsToDeleteInModal]}
        />
      )}

      {deleteSingleItem}

      <Card className="space-y-16">
        <div className="text-20">Section Items:</div>

        <div className="flex justify-between w-full space-x-8">
          {!activeMultipleSelection ? (
            <button
              tabIndex={0}
              className="btn btn-outline btn-error"
              onClick={() => {
                setActiveMultipleSelection(true);
                configItems.map((_: unknown, index: string | number | undefined) => onSelectItem(index));
              }}
            >
              SELECT ALL ITEM
            </button>
          ) : (
            <button
              tabIndex={0}
              className="btn btn-outline btn-error"
              onClick={() => {
                setDeleteIndexSet(new Set());
                setActiveMultipleSelection(false);
              }}
            >
              CANCEL
            </button>
          )}
          <button
            tabIndex={0}
            className="flex items-center justify-center space-x-4 btn btn-error"
            onClick={() => setShowConfirmModal(true)}
            disabled={!deleteIndexSet.size || !activeMultipleSelection}
          >
            <RiDeleteBinLine />
            <span>Delete {`(${deleteIndexSet.size})`}</span>
          </button>
        </div>

        <div className="flex w-full pt-6">
          <ul className="px-6 border-[1px] border-systemGrays04LabelQuaternary bg-systemGrays04LabelQuaternary">
            {configItems?.map((_: unknown, index: number) => (
              <li className="h-[80px] flex justify-center items-center" key={index}>
                {index + 1}
              </li>
            ))}
          </ul>

          <DraggableArea className="w-full" draggableItems={fields} onRenderDraggableItem={onRenderDraggableItem} />
        </div>

        <div className="w-full dropdown">
          <button tabIndex={0} className="btn btn-block btn-error" disabled={!layoutId || !!deleteIndexSet.size || activeMultipleSelection}>
            + Add item
          </button>
          <ul tabIndex={0} className="w-full p-2 shadow menu dropdown-content bg-base-100 rounded-box">
            {getSectionNamesByLayout(layoutId)?.map((itemType, i) => (
              <li key={i}>
                <button
                  className="m-1 btn btn-ghost"
                  onClick={() => {
                    onAddSectionItem(itemType);
                  }}
                >
                  {itemType}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </>
  );
};

export default AddItemInSectionPresenter;

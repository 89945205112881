import { Control, FieldValues, UseFormRegister } from 'react-hook-form';

import ArraysInput from '../../../components/form/ArraysInput';
import Input from '../../../components/form/Input';
import RadioGroupButton from '../../../components/form/RadioGroupButton';
import { EBOOK_HAS_DISCOUNT, EBOOK_HAS_DISCOUNT_END_DATE, IConfigItemType } from '../validates/sectionBuilderSchema';

import SelectDiscountSetting from './SelectDiscountSetting';

export interface IPropsQueryEbookBundleItem {
  type: IConfigItemType.QueryEbookBundle;
  hasDiscount?: string;
  hasDiscountEnded?: string;
  gteDiscountRatio?: number;
  lteDiscountRatio?: number;
  anyUserIds?: string[];
  whitelistIds?: string[];
}

interface IPropsQueryEbookBundleSectionItem {
  index: number;
  itemData: IPropsQueryEbookBundleItem;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
}

const QueryEbookBundleSectionItem = (props: IPropsQueryEbookBundleSectionItem) => {
  const { index, itemData, control, register } = props;

  // ตย. input
  //   {
  //     "platform": "pinto",
  //     "note": "test note",
  //     "layoutId": "book_banner",
  //     "title": "test title",
  //     "target": null,
  //     "configItems": [
  //         {
  //             "type": "query-ebook-bundle",
  //             "hasDiscount": true,
  //             "gteDiscountRatio": "0.1",
  //             "lteDiscountRatio": "0.5",
  //             "hasDiscountEnded": true
  //         }
  //     ]
  // }

  return (
    <div className="relative flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Query Ebook Bundle</span>
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary bg-systemGrays08FillQuaternary !peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <SelectDiscountSetting options={EBOOK_HAS_DISCOUNT} prefixName={`configItems.${index}`} name={[`configItems.${index}.hasDiscount`]} label={'HasDiscount'} />

            {itemData.hasDiscount && (
              <div className="flex items-center justify-between gap-x-8">
                <Input
                  type="number"
                  register={() => register(`configItems.${index}.gteDiscountRatio`)}
                  label="min_DiscountId"
                  name="minDiscountId"
                  helperText="limited 0-50"
                  placeholder="min_DiscountId"
                  className="flex-1"
                  min="0"
                  max="50"
                />
                <span>-</span>
                <Input
                  type="number"
                  register={() => register(`configItems.${index}.lteDiscountRatio`)}
                  label="max_DiscountId"
                  name="maxDiscountId"
                  helperText="limited 0-50"
                  placeholder="max_DiscountId"
                  className="flex-1"
                  min="0"
                  max="50"
                />
              </div>
            )}

            {itemData.hasDiscount && (
              <RadioGroupButton options={EBOOK_HAS_DISCOUNT_END_DATE} name={`configItems.${index}.hasDiscountEnded`} register={register} label={'HasDiscountEndDate'} />
            )}

            <div>
              <ArraysInput control={control} name={`configItems.${index}.anyUserIds`} label="UserIds" helperText="Input User ID" placeholder="userIds" />
              <ArraysInput control={control} name={`configItems.${index}.whitelistIds`} label="whitelistIds" helperText="Input ebook ID" placeholder="whitelistIds" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryEbookBundleSectionItem;

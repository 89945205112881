import classNames from 'classnames';
import { isBoolean, isEmpty, isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { Link, useHistory, useLocation } from 'react-router-dom';

import ErrorModal from '../../app/modals/ErrorModal';
import { IPropsEbookBundleItem } from '../../app/sections/components/EbookBundleSectionItem';
import { IPropsEbookItem } from '../../app/sections/components/EbookSectionItem';
import { IPropsEditorReviewItem } from '../../app/sections/components/EditorReviewSectionItem';
import { IPropsFeedItem } from '../../app/sections/components/FeedSectionItem';
import { IPropsLinkItem } from '../../app/sections/components/LinkSectionItem';
import { IPropsProductSetItem } from '../../app/sections/components/ProductSetSectionItem';
import { IPropsQueryEbookBundleItem } from '../../app/sections/components/QueryEbookBundleSectionItem';
import { IPropsQueryEbookItem } from '../../app/sections/components/QueryEbookSectionItem';
import { IPropsQueryProductSetItem } from '../../app/sections/components/QueryProductSetSectionItem';
import { IPropsSearchItem } from '../../app/sections/components/SearchSectionItem';
import AddItemInSectionPresenter from '../../app/sections/presenters/AddItemInSectionPresenter';
import SectionDetailPresenter from '../../app/sections/presenters/SectionDetailPresenter';
import SectionNotePresenter from '../../app/sections/presenters/SectionNotePresenter';
import SelectLayoutSectionPresenter from '../../app/sections/presenters/SelectLayoutSectionPresenter';
import SectionBuilderFormObj, {
  EXPLORE_TYPE_VALUE,
  IConfigItemType,
  ISectionBuilderFields,
  ISectionLayoutId,
  ISectionTargetType,
  SECTION_BUILDER_FIELD_KEYS,
} from '../../app/sections/validates/sectionBuilderSchema';
import Breadcrumbs from '../../components/Breadcrumbs';
import { ContentType, FeedPref, ProductType } from '../../core/graphql/types';
import useMutationPintoCreateSection from '../../hooks/useMutationPintoCreateSection';
import useMutationPintoUpdateSection from '../../hooks/useMutationPintoUpdateSection';
import useQueryPintoSection from '../../hooks/useQueryPintoSection';
import useHookForm from '../../utils/form/useHookForm';
import { camelToSnakeCase } from '../../utils/string';

type IPropsConfigItem =
  | IPropsEbookItem
  | IPropsFeedItem
  | IPropsLinkItem
  | IPropsQueryEbookItem
  | IPropsQueryEbookBundleItem
  | IPropsSearchItem
  | IPropsProductSetItem
  | IPropsQueryProductSetItem
  | IPropsEbookBundleItem
  | IPropsEditorReviewItem;

const MAPPED_TARGET_TYPE: { [key: string]: string } = {
  Feed: 'feed',
  Link: 'link',
  User: 'user',
  Explore: 'explore',
  SearchLink: 'search',
  ExploreProductSet: 'explore-product-set',
};

export const onGetTargetTypeData = (targetType?: string) => {
  if (!targetType) return null;
  return MAPPED_TARGET_TYPE[targetType] || null;
};

export const onConvertDataToConfigItems = (configItem: IPropsConfigItem) => {
  switch (configItem.type) {
    case IConfigItemType.Ebook: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        _id: configItem._id,
      };

      return omitBy(tempConfigItem, isNil);
    }

    case IConfigItemType.EbookBundle: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        _id: configItem._id,
      };

      return omitBy(tempConfigItem, isNil);
    }

    case IConfigItemType.Feed: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        platform: 'pinto',
        // optional
        slug: isEmpty(configItem.slug) ? null : configItem?.slug,
        title: isEmpty(configItem.title) ? null : configItem?.title,
        description: isEmpty(configItem.description) ? null : configItem?.description,
        coverImage: isEmpty(configItem.coverImage) ? null : configItem?.coverImage,
        bannerImage: isEmpty(configItem.bannerImage) ? null : configItem?.bannerImage,
        popupImageUrl: isEmpty(configItem.popupImageUrl) ? null : configItem?.popupImageUrl,
      };

      return omitBy(tempConfigItem, isNil);
    }

    case IConfigItemType.Link: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        url: configItem.url,
        // optional
        title: isEmpty(configItem.title) ? null : configItem?.title,
        description: isEmpty(configItem.description) ? null : configItem?.description,
        coverImage: isEmpty(configItem.coverImage) ? null : configItem?.coverImage,
        bannerImage: isEmpty(configItem.bannerImage) ? null : configItem?.bannerImage,
        popupImageUrl: isEmpty(configItem.popupImageUrl) ? null : configItem?.popupImageUrl,
      };

      return omitBy(tempConfigItem, isNil);
    }
    case IConfigItemType.QueryEbook: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        categoryId: Number(configItem.categoryId),
        // optional
        gteRating: Number(configItem.gteRating) ? Number(configItem.gteRating) : null,
        hasDiscount: isBoolean(configItem.hasDiscount) ? configItem.hasDiscount : null,
        gteDiscountRatio: Number(configItem.gteDiscountRatio) ? Number(configItem.gteDiscountRatio) / 100 : null,
        lteDiscountRatio: Number(configItem.lteDiscountRatio) ? Number(configItem.lteDiscountRatio) / 100 : null,
        anyTypes: isEmpty(configItem.anyTypes) ? null : configItem.anyTypes,
        anyUserIds: isEmpty(configItem.anyUserIds) ? null : configItem.anyUserIds,
        whitelistIds: isEmpty(configItem.whitelistIds) ? null : configItem.whitelistIds,
        blacklistIds: isEmpty(configItem.blacklistIds) ? null : configItem.blacklistIds,
        tagNames: isEmpty(configItem.tagNames) ? null : configItem.tagNames,
        anyTagNames: isEmpty(configItem.anyTagNames) ? null : configItem.anyTagNames,
        isFree: isBoolean(configItem.isFree) ? configItem.isFree : null,
        sortType: isEmpty(configItem.sortType) ? null : configItem.sortType,
        sortBy: isEmpty(configItem.sortBy) ? null : configItem.sortBy,
        limit: Number(configItem.limit) ? Number(configItem.limit) : null,
      };
      return omitBy(tempConfigItem, isNil);
    }
    case IConfigItemType.QueryEbookBundle: {
      console.log(configItem.hasDiscountEnded, Boolean(configItem.hasDiscountEnded));
      const tempConfigItem = {
        // require
        type: configItem.type,
        hasDiscount: isBoolean(configItem.hasDiscount) ? configItem.hasDiscount : null,
        hasDiscountEnded: configItem.hasDiscountEnded === 'null' ? null : Boolean(configItem.hasDiscountEnded),
        gteDiscountRatio: Number(configItem.gteDiscountRatio) ? Number(configItem.gteDiscountRatio) / 100 : null,
        lteDiscountRatio: Number(configItem.lteDiscountRatio) ? Number(configItem.lteDiscountRatio) / 100 : null,
        anyUserIds: isEmpty(configItem.anyUserIds) ? null : configItem.anyUserIds,
        whitelistIds: isEmpty(configItem.whitelistIds) ? null : configItem.whitelistIds,
      };
      return omitBy(tempConfigItem, isNil);
    }
    case IConfigItemType.Search: {
      let result;
      if (configItem?.searchType === 'user') {
        result = {
          // require
          type: configItem.type,
          searchType: 'user',
          // optional
          searchText: isEmpty(configItem.searchText) ? null : configItem?.searchText,
          title: isEmpty(configItem.title) ? null : configItem?.title,
          description: isEmpty(configItem.description) ? null : configItem?.description,
          coverImage: isEmpty(configItem.coverImage) ? null : configItem?.coverImage,
          bannerImage: isEmpty(configItem.bannerImage) ? null : configItem?.bannerImage,
          popupImageUrl: isEmpty(configItem.popupImageUrl) ? null : configItem?.popupImageUrl,
        };
      }

      if (configItem?.searchType === 'ebook') {
        result = {
          // require
          type: configItem.type,
          searchType: 'ebook',
          sortBy: configItem.sortBy,
          categoryId: Number(configItem.categoryId),
          // optional
          searchText: isEmpty(configItem.searchText) ? null : configItem?.searchText,
          title: isEmpty(configItem.title) ? null : configItem?.title,
          description: isEmpty(configItem.description) ? null : configItem?.description,
          coverImage: isEmpty(configItem.coverImage) ? null : configItem?.coverImage,
          bannerImage: isEmpty(configItem.bannerImage) ? null : configItem?.bannerImage,
          popupImageUrl: isEmpty(configItem.popupImageUrl) ? null : configItem?.popupImageUrl,
          searchField: isEmpty(configItem.searchField) ? null : configItem?.searchField,
          gteRating: Number(configItem.gteRating) ? Number(configItem.gteRating) : null,
          anyPriceTypes: isEmpty(configItem.anyPriceTypes) ? null : configItem.anyPriceTypes,
          anyTypes: isEmpty(configItem.anyTypes) ? null : configItem.anyTypes,
          //TODO: waiting for clear API
          // sortType: isEmpty(configItem.sortType) ? null : configItem.sortType,
        };
      }

      return omitBy(result, isNil);
    }
    case IConfigItemType.ProductSet: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        _id: configItem._id,
      };

      return omitBy(tempConfigItem, isNil);
    }
    case IConfigItemType.QueryProductSet: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        sortType: isEmpty(configItem.sortType) ? null : configItem.sortType,
        sortBy: isEmpty(configItem.sortBy) ? null : configItem.sortBy,
        limit: Number(configItem.limit) ? Number(configItem.limit) : null,
        categoryId: Number(configItem.categoryId),
        anyUserIds: isEmpty(configItem.anyUserIds) ? null : configItem.anyUserIds,
        whitelistIds: isEmpty(configItem.whitelistIds) ? null : configItem.whitelistIds,
        blacklistIds: isEmpty(configItem.blacklistIds) ? null : configItem.blacklistIds,
      };

      return omitBy(tempConfigItem, isNil);
    }

    case IConfigItemType.EditorReviewEbook: {
      const tempConfigItem = {
        // require
        type: configItem.type,
        _id: configItem._id, // ebookId
        review: isEmpty(configItem.review) ? null : configItem?.review,
        reviewBy: isEmpty(configItem.reviewBy) ? null : configItem?.reviewBy,
      };

      return omitBy(tempConfigItem, isNil);
    }
  }
};

const SectionBuilderPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const searchSlug = searchParams.get('id');

  const { onQueryPintoSection, dataPintoSection } = useQueryPintoSection();
  const { onMutatePintoCreateSection, isLoadingMutatePintoCreateSection } = useMutationPintoCreateSection();
  const { onMutatePintoUpdateSection, isLoadingMutatePintoUpdateSection } = useMutationPintoUpdateSection();

  const { methods, setValue, watch } = useHookForm<ISectionBuilderFields>({
    objectShapeForm: SectionBuilderFormObj,
  });
  const { remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'configItems', // unique name for your Field Array
  });
  const [title, target, layoutId, configItems, note, contentType] = watch(['title', 'target', 'layoutId', 'configItems', 'note', 'contentType']);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const validateTarget = useCallback(() => {
    switch (target?.type) {
      case ISectionTargetType.PRODUCT_SET:
      case ISectionTargetType.EXPLORE:
        return isEmpty(target?.categoryId);
      default:
        return false;
    }
  }, [target?.type, target?.categoryId]);

  const isEnableSubmitButton = useMemo(() => {
    if (layoutId === ISectionLayoutId.MainBanner || layoutId === ISectionLayoutId.TagCloud) {
      return isEmpty(note) || isEmpty(layoutId);
    } else {
      return isEmpty(note) || isEmpty(title) || isEmpty(layoutId) || validateTarget();
    }
  }, [layoutId, note, title, validateTarget]);

  useEffect(() => {
    if (searchSlug) {
      onQueryPintoSection(searchSlug);
    }
  }, [onQueryPintoSection, searchSlug]);

  useEffect(() => {
    setValue?.('target', null);
  }, [setValue]);

  useEffect(() => {
    if (dataPintoSection) {
      setValue(SECTION_BUILDER_FIELD_KEYS.TITLE, dataPintoSection.pintoSection?.title as never);
      setValue(
        SECTION_BUILDER_FIELD_KEYS.TARGET,
        (dataPintoSection.pintoSection?.target
          ? { ...dataPintoSection.pintoSection?.target, type: onGetTargetTypeData(dataPintoSection.pintoSection?.target?.__typename) }
          : null) as never,
      );
      setValue(
        SECTION_BUILDER_FIELD_KEYS.LAYOUT_ID,
        dataPintoSection.pintoSection?.camelCaseLayoutId === 'bookCover2'
          ? ('book_cover_2' as never)
          : (camelToSnakeCase(dataPintoSection.pintoSection?.camelCaseLayoutId || '') as never),
      );
      const mappedConfigItems = dataPintoSection.pintoSection?.configItems?.map((item) => ({
        ...item,
        ...(item.gteDiscountRatio && { gteDiscountRatio: item.gteDiscountRatio ? item.gteDiscountRatio * 100 : null }),
        ...(item.lteDiscountRatio && { lteDiscountRatio: item.lteDiscountRatio ? item.lteDiscountRatio * 100 : null }),
      }));
      setValue(SECTION_BUILDER_FIELD_KEYS.CONFIG_ITEMS, mappedConfigItems as never);
      setValue(SECTION_BUILDER_FIELD_KEYS.NOTE, dataPintoSection.pintoSection?.note as never);
    }
  }, [dataPintoSection, setValue]);

  const onConvertTargetData = useCallback(() => {
    if (!target) return null;
    switch (target.type) {
      case 'search':
        return {
          type: target.type,
          searchType: target.searchType || 'ebook',
          searchText: target.searchText || '',
          searchField: target.searchField || null,
          categoryId: target.categoryId || null,
          sortBy: target.sortBy || null,
          sortType: target.sortType || null,
          gteRating: target.gteRating ? Number(target.gteRating) : null,
          anyPriceTypes: target.anyPriceTypes || [],
          anyTypes: target.anyTypes || [],
          anyWritingTypes: target.anyWritingTypes || [],
        };
      case 'explore':
        return {
          type: target.type,
          exploreType: target.exploreType || EXPLORE_TYPE_VALUE.Trending,
          categoryId: target.categoryId || null,
          sortBy: target.sortBy || null,
          sortType: target.sortType || null,
          gteRating: target.gteRating ? Number(target.gteRating) : null,
          anyPriceTypes: target.anyPriceTypes || [],
          anyTypes: target.anyTypes || [],
          anyFileTypes: target.anyFileTypes || [],
          anyWritingTypes: target.anyWritingTypes || [],
        };
      default:
        return target;
    }
  }, [target]);

  const onConvertDataInput = useCallback(() => {
    const configItemsInput = configItems?.map((configItem: IPropsConfigItem) => onConvertDataToConfigItems(configItem));
    let targetData = onConvertTargetData();

    if (targetData && targetData.type && ['search', 'explore'].includes(targetData.type)) {
      if (contentType === ContentType.Comic) {
        targetData.anyWritingTypes = targetData.anyWritingTypes || [];
      } else {
        delete targetData.anyWritingTypes;
      }
    }

    // If targetData exists but no valid type, remove it
    if (targetData && !targetData.type) {
      targetData = undefined;
    }

    return {
      target: targetData,
      ...omitBy(
        {
          title,
          pref: FeedPref.Default,
          productType: ProductType.Ebook,
          layoutId,
          configItems: configItemsInput,
          note: isEmpty(note) ? null : note,
        },
        isNil,
      ),
    };
  }, [configItems, layoutId, note, onConvertTargetData, contentType, title]);

  const onSubmit = useCallback(async () => {
    const id = searchSlug;
    const input = onConvertDataInput();

    if (id) {
      const isSuccess = await onMutatePintoUpdateSection(id, input);
      if (isSuccess) {
        return history.push(`/feedAndSection/sections`);
      } else {
        setShowErrorModal(true);
      }
    } else {
      const isSuccess = await onMutatePintoCreateSection(input);
      if (isSuccess) {
        return history.push(`/feedAndSection/sections`);
      } else {
        setShowErrorModal(true);
      }
    }
  }, [searchSlug, onConvertDataInput, onMutatePintoUpdateSection, history, onMutatePintoCreateSection]);
  return (
    <div>
      {(isLoadingMutatePintoCreateSection || isLoadingMutatePintoUpdateSection) && (
        <div className="flex items-center justify-center p-32 rounded-lg bg-systemGrays06FillSecondary">Loading...</div>
      )}

      {showErrorModal && <ErrorModal onConFirm={() => setShowErrorModal(false)} confirmText="ตกลง" errorMessages={['กรุณาตรวจสอบความถูกต้อง แล้วลองบันทึกอีกรอบ']} />}

      <div className="px-24 bg-componentsBgGrouped02">
        {/* section: breadcrumbs */}
        <Breadcrumbs items={[{ name: 'Sections', url: '/feedAndSection/sections' }, { name: 'Section Builder' }]} />

        {/* section: title */}
        <div className="flex justify-between pt-5">
          <h1 className="font-dbh text-[28px]">Section Builder</h1>
        </div>

        {/* section: main */}
        <div className="mb-0 divider opacity-80"></div>
      </div>

      <FormProvider {...methods}>
        <div className="flex flex-row w-full space-x-12">
          <div className="w-full space-y-2">
            <SectionNotePresenter />
            <SelectLayoutSectionPresenter
              isEnableChangeLayout={!configItems?.length}
              className={classNames('w-[100vw]', { 'cursor-not-allowed': configItems?.length })}
              selectedLayout={layoutId}
              onSelectLayout={(value) => {
                if (layoutId !== value) {
                  remove();
                  setValue('layoutId', value);
                }
                if (value !== ISectionLayoutId.BookBanner && target?.type === ISectionTargetType.PRODUCT_SET) {
                  setValue('target', null);
                }
              }}
              configItemsCount={configItems?.length || 0}
            />
            {layoutId ? (
              <>
                <SectionDetailPresenter />
                <AddItemInSectionPresenter />
              </>
            ) : null}
          </div>
          {/* <div className="flex-1">
            <SectionPreviewPresenter />
          </div> */}
        </div>
      </FormProvider>

      {layoutId ? (
        <div className="p-24 my-16 space-y-8 bg-componentsBgGrouped02 rounded-[8px]">
          <div className="text-systemGrays01LabelPrimary">JSON VIEWER</div>
          <div className="p-8 bg-white rounded-[8px]">
            <ReactJson src={{ note, layoutId, title, target, configItems }} displayDataTypes={false} />
          </div>
        </div>
      ) : null}

      <div className="sticky bottom-0 z-50 flex items-center justify-between p-16 mt-24 border bg-componentsBgGrouped02 border-componentsDivider">
        <div className={classNames('pt-4 text-12', { 'text-systemGrays01LabelPrimary': configItems?.length, 'text-systemGrays03LabelTertiary': !configItems?.length })}>
          {`*หากต้องการเปลี่ยน Section Layout กรุณาลบ ConfigItems เดิมออกทั้งหมดก่อน: (ConfigItems: ${configItems?.length || 0})`}
        </div>
        {/* </div> */}
        <div className="space-x-12 ">
          <Link to="/feedAndSection/sections">
            <button className="btn btn-outline">CANCEL</button>
          </Link>
          <button className="btn btn-error" disabled={isEnableSubmitButton} onClick={onSubmit}>
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionBuilderPage;

/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import images from '../../../assets/images';
import { EbookWritingType, SortType } from '../../../core/graphql/types';
// import key from '../../../fictionlog/components/icons/components/Key';
import { IErrorObject } from '../../../utils/validator';

export enum SECTION_BUILDER_FIELD_KEYS {
  TITLE = 'title',
  TARGET = 'target',
  LAYOUT_ID = 'layoutId',
  CONFIG_ITEMS = 'configItems',
  NOTE = 'note',
}

export interface IErrorsSectionBuilder {
  title?: IErrorObject;
  // pref?: IErrorObject;
  // productType?: IErrorObject;
  contentType?: IErrorObject;
  layoutId?: IErrorObject;
  target?: IErrorObject;
  configItems?: IErrorObject;
  note?: IErrorObject;
  // isNCContent?: IErrorObject;
  // categoryTagId?: IErrorObject;
}

export type ISectionBuilderFields = keyof IErrorsSectionBuilder;

// export type ISectionLayoutId = 'BookCover' | 'BookCover2' | 'BookBanner' | 'LinkBanner' | 'LinkBannerFullWidth' | 'MainBanner' | 'TagCloud' | 'Tank' | 'Testimonial';

export enum ISectionLayoutId {
  BookBanner = 'book_banner',
  BookCover = 'book_cover',
  BookCover2 = 'book_cover_2',
  LinkBanner = 'link_banner',
  LinkBannerFullWidth = 'link_banner_fullWidth',
  MainBanner = 'main_banner',
  TagCloud = 'tag_cloud',
  Rank = 'rank',
  Testimonial = 'testimonial',
  EditorPick = 'editor_review',
}

export enum IProduct_SET_SORT_BY {
  Created = 'created',
  Updated = 'updated',
  Published = 'published',
  Random = 'random',
  LatestAdded = 'latestAdded',
  Trending = 'trending',
  Bestseller7Days = 'bestseller7Days',
  Bestseller30Days = 'bestseller30Days',
  Bestseller = 'bestseller',
  PriceThb = 'priceThb',
  Title = 'title',
}

export const SECTION_LAYOUT_OPTIONS = [
  { layoutId: ISectionLayoutId.BookBanner, label: 'Book Banner', imageUrl: images.pinto.sections.bookBanner },
  { layoutId: ISectionLayoutId.BookCover, label: 'Book Cover', imageUrl: images.pinto.sections.bookCover },
  { layoutId: ISectionLayoutId.BookCover2, label: 'Book Cover 2', imageUrl: images.pinto.sections.bookCover2 },
  { layoutId: ISectionLayoutId.Rank, label: 'Rank', imageUrl: images.pinto.sections.rank },
  { layoutId: ISectionLayoutId.MainBanner, label: 'Main Banner', imageUrl: images.pinto.sections.mainBanner },
  { layoutId: ISectionLayoutId.TagCloud, label: 'Tag Cloud', imageUrl: images.pinto.sections.tagCloud },
  { layoutId: ISectionLayoutId.EditorPick, label: 'Editor`s Pick', imageUrl: images.pinto.sections.editorPick },
  // { layoutId: ISectionLayoutId.LinkBanner, label: 'Link Banner', imageUrl: images.pinto.sections.linkBanner },
  // { layoutId: ISectionLayoutId.LinkBannerFullWidth, label: 'Link Banner FullWidth', imageUrl: images.pinto.sections.linkBannerFullWidth },
  // { layoutId: ISectionLayoutId.Testimonial, label: 'Testimonial', imageUrl: images.pinto.sections.testimonial },
];

export const SORT_BY_QUERY_EBOOK_OPTIONS = [
  {
    label: 'ไม่ระบุ',
    value: [
      { keyName: 'sortBy', value: null },
      { keyName: 'sortType', value: null },
    ],
  },
  {
    label: 'ยอดวิว',
    value: [
      { keyName: 'sortBy', value: 'viewsCount' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'เผยแพร่ล่าสุด',
    value: [
      { keyName: 'sortBy', value: 'published' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'สร้างล่าสุด',
    value: [
      { keyName: 'sortBy', value: 'created' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'เทรนดิ้ง',
    value: [
      { keyName: 'sortBy', value: 'trending' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ยอดนิยมตลอดกาล',
    value: [
      { keyName: 'sortBy', value: 'popular' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ยอดนิยม 7 วันที่แล้ว',
    value: [
      { keyName: 'sortBy', value: 'popular7Days' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ยอดนิยม 30 วันที่แล้ว',
    value: [
      { keyName: 'sortBy', value: 'popular30Days' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ขายดี 7 วันที่แล้ว',
    value: [
      { keyName: 'sortBy', value: 'bestseller7Days' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ขายดี 30 วันที่แล้ว',
    value: [
      { keyName: 'sortBy', value: 'bestseller30Days' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ราคาต่ำ-สูง',
    value: [
      { keyName: 'sortBy', value: 'priceThb' },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  }, // และต้องส่ง sortType เป็น asc มาด้วย

  {
    label: 'ราคาสูง-ต่ำ',
    value: [
      { keyName: 'sortBy', value: 'priceThb' },
      { keyName: 'sortType', value: null },
    ],
  },

  {
    label: 'เรียงตามตัวอักษร',
    value: [
      { keyName: 'sortBy', value: 'title' },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  }, // และต้องส่ง sortType เป็น asc มาด้วย (ถ้าไม่ส่ง default จะเป็น desc ซึ่งหมายความว่าจะเรียงแบบ ฮ - ก )

  {
    label: 'สุ่ม',
    value: [
      { keyName: 'sortBy', value: 'random' },
      { keyName: 'sortType', value: null },
    ],
  },
];

//TODO: recheck
export const SORT_BY_SEARCH_OPTIONS = [
  {
    label: 'ที่เกี่ยวข้อง',
    value: [
      { keyName: 'sortBy', value: 'relevant' },
      { keyName: 'sortType', value: null },
    ],
  },
  {
    label: 'วันที่เผยแพร่',
    value: [
      { keyName: 'sortBy', value: 'published' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ราคาต่ำ-สูง',
    value: [
      { keyName: 'sortBy', value: 'priceThb' },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  }, // และต้องส่ง sortType เป็น asc มาด้วย
  {
    label: 'ราคาสูง-ต่ำ',
    value: [
      { keyName: 'sortBy', value: 'priceThb' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },

  {
    label: 'เรียงตามตัวอักษร',
    value: [
      { keyName: 'sortBy', value: 'title' },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
];

export const SORT_BY_EXPLORE_OPTIONS = [
  {
    label: 'วันที่เผยแพร่',
    value: [
      { keyName: 'sortBy', value: 'published' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ราคาต่ำ-สูง',
    value: [
      { keyName: 'sortBy', value: 'priceThb' },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  }, // และต้องส่ง sortType เป็น asc มาด้วย
  {
    label: 'ราคาสูง-ต่ำ',
    value: [
      { keyName: 'sortBy', value: 'priceThb' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },

  {
    label: 'เรียงตามตัวอักษร',
    value: [
      { keyName: 'sortBy', value: 'title' },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
];

export const SORT_BY_EXPLORE_BEST_SELLER_OPTIONS = [
  {
    label: '7 วัน',
    value: [
      { keyName: 'sortBy', value: 'bestseller7Days' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: '30 วัน',
    value: [
      { keyName: 'sortBy', value: 'bestseller30Days' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  }, // และต้องส่ง sortType เป็น asc มาด้วย

  {
    label: 'ตลอดกาล',
    value: [
      { keyName: 'sortBy', value: 'bestseller' },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
];

export const SORT_BY_PRODUCT_SET_OPTIONS = [
  {
    label: 'เผยแพร่ล่าสุด',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Published },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'เพิ่มล่าสุด',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.LatestAdded },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'เทรนดิ้ง',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Trending },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'ขายดี 7 วัน',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Bestseller7Days },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'ขายดี 30 วัน',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Bestseller30Days },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'ขายดีตลอดกาล',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Bestseller },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'ราคาสูง-ต่ำ',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.PriceThb },
      { keyName: 'sortType', value: SortType.Desc },
    ],
  },
  {
    label: 'ราคาต่ำ-สูง',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.PriceThb },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  }, // และต้องส่ง sortType เป็น asc มาด้วย
  {
    label: 'เรียงตามตัวอักษร',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Title },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
  {
    label: 'สุ่ม',
    value: [
      { keyName: 'sortBy', value: IProduct_SET_SORT_BY.Random },
      { keyName: 'sortType', value: SortType.Asc },
    ],
  },
];

export const RATING_OPTIONS = [
  { label: 'ทั้งหมด', value: null },
  { label: '≥ 4 Stars', value: 4 },
  { label: '≥ 3 Stars', value: 3 },
  { label: '≥ 2 Stars', value: 2 },
  { label: '≥ 1 Stars', value: 1 },
];

export const IS_FREE_OPTIONS = [
  {
    label: 'ทั้งหมด',
    value: [{ keyName: 'isFree', value: '' }],
  },
  {
    label: 'ฟรี',
    value: [
      { keyName: 'isFree', value: true },
      { keyName: 'hasDiscount', value: null },
      { keyName: 'lteDiscountRatio', value: null },
      { keyName: 'gteDiscountRatio', value: null },
    ],
  },
  {
    label: 'ไม่ฟรี',
    value: [{ keyName: 'isFree', value: false }],
  },
];

export const EBOOK_IS_FREE_OPTIONS = [
  { label: 'ทั้งหมด', value: '' },
  { label: 'ฟรี', value: true },
  { label: 'ไม่ฟรี', value: false },
];

export const EBOOK_HAS_DISCOUNT = [
  {
    label: 'ทั้งหมด',
    value: [
      { keyName: 'hasDiscount', value: null },
      { keyName: 'lteDiscountRatio', value: null },
      { keyName: 'gteDiscountRatio', value: null },
    ],
  },
  {
    label: 'ไม่มีส่วนลด',
    value: [
      { keyName: 'hasDiscount', value: false },
      { keyName: 'lteDiscountRatio', value: null },
      { keyName: 'gteDiscountRatio', value: null },
    ],
  },
  { label: 'มีส่วนลด', value: [{ keyName: 'hasDiscount', value: true }] },
];

export const EBOOK_HAS_DISCOUNT_END_DATE = [
  {
    label: 'ทั้งหมด',
    value: 'null',
  },
  {
    label: 'ส่วนลดแบบไม่มีระยะเวลาสิ้นสุด',
    value: 'false',
  },
  { label: 'ส่วนลดแบบมีระยะเวลาสิ้นสุด', value: 'true' },
];

export const EBOOK_TYPE_OPTIONS = [
  { label: 'ทั้งหมด', value: [] },
  { label: 'อีบุ๊กไทย', value: ['thai'] },
  { label: 'อีบุ๊กแปล', value: ['translatedThai'] },
];
export const EBOOK_FILE_TYPE_OPTIONS = [
  { label: 'ทั้งหมด', value: [] },
  { label: 'PDF', value: ['pdf'] },
  { label: 'EPUB', value: ['epub'] },
];
export const PRICE_SETTING_OPTIONS = [
  { label: 'ทั้งหมด', value: [] },
  { label: 'ฟรี', value: ['free'] },
  { label: 'ส่วนลดหนังสือ', value: ['onSale'] },
  { label: 'ฟรีและส่วนลดหนังสือ', value: ['free', 'onSale'] },
];

export const CARTOON_TYPE_OPTIONS = [
  { label: 'ทั้งหมด', value: [] },
  { label: 'รวมเล่ม', value: [EbookWritingType.Bundle] },
  { label: 'รายตอน', value: [EbookWritingType.Chapter] },
];

export const SPECIAL_SEARCH_OPTIONS = [
  { label: 'All', value: null },
  { label: 'Title', value: 'title' },
  { label: 'Tag', value: 'tag' },
  { label: 'Author/Translator', value: 'authorOrTranslator' },
];

export enum EXPLORE_TYPE_VALUE {
  Trending = 'trending',
  BestSeller = 'bestseller',
  NewArrival = 'newArrival',
  Free = 'free',
  All = 'all',
}

export const EXPLORE_TYPE_OPTIONS = [
  { label: 'เทรนดิ้ง', value: EXPLORE_TYPE_VALUE.Trending },
  { label: 'ขายดี', value: EXPLORE_TYPE_VALUE.BestSeller },
  { label: 'มาใหม่', value: EXPLORE_TYPE_VALUE.NewArrival },
  { label: 'ฟรีอีบุ๊ก', value: EXPLORE_TYPE_VALUE.Free },
  { label: 'ทั้งหมด', value: EXPLORE_TYPE_VALUE.All },
];

export const SEARCH_TYPE_OPTIONS = [
  { label: 'Ebook', value: [{ keyName: 'searchType', value: 'ebook' }] },
  {
    label: 'User',
    value: [
      { keyName: 'searchType', value: 'user' },
      { keyName: 'searchField', value: null },
      { keyName: 'sortBy', value: null },
      { keyName: 'sortType', value: null },
      { keyName: 'categoryId', value: null },
      { keyName: 'gteRating', value: null },
      { keyName: 'anyTypes', value: null },
      { keyName: 'anyPriceTypes', value: null },
    ],
  },
];

export enum ISectionTargetType {
  NONE = '',
  FEED = 'feed',
  LINK = 'link',
  USER = 'user',
  SEARCH = 'search',
  EXPLORE = 'explore',
  PRODUCT_SET = 'explore-product-set',
}

export const TARGET_TYPE_OPTIONS = [
  { label: 'ไม่ระบุ', value: '' },
  { label: 'Feed', value: ISectionTargetType.FEED },
  { label: 'Link', value: ISectionTargetType.LINK },
  { label: 'User', value: ISectionTargetType.USER },
  { label: 'Explore', value: ISectionTargetType.EXPLORE },
  { label: 'Search', value: ISectionTargetType.SEARCH },
  { label: 'Product set', value: ISectionTargetType.PRODUCT_SET },
];

export const TARGET_SEARCH_TYPE_OPTIONS = [
  { label: 'อีบุ๊ก', value: 'ebook' },
  { label: 'ผู้ใช้', value: 'user' },
];

export const TARGET_SEARCH_FIELD_OPTIONS = [
  { label: 'ทั้งหมด', value: 'All' },
  { label: 'ชื่อเรื่อง', value: 'Title' },

  { label: 'แท็ก', value: 'Tag' },
  { label: 'นักเขียน/นักแปล', value: 'AuthorOrTranslator' },
];

//TODO: use only section type in soft lunch
export enum IConfigItemType {
  Ebook = 'ebook',
  EbookBundle = 'ebook-bundle',
  Feed = 'feed',
  Link = 'link',
  QueryEbook = 'query-ebook',
  QueryEbookBundle = 'query-ebook-bundle',

  Search = 'search',
  // Explore = 'explore',
  // User = 'user',
  ProductSet = 'product-set',
  QueryProductSet = 'query-product-set',
  EditorReviewEbook = 'editor-review-ebook',
}

export const ADD_ITEM_OPTION_BY_LAYOUT_ID = {
  bookBanner: [
    IConfigItemType.Ebook,
    IConfigItemType.QueryEbook,
    IConfigItemType.ProductSet,
    IConfigItemType.QueryProductSet,
    IConfigItemType.EbookBundle,
    IConfigItemType.QueryEbookBundle,
  ],
  bookCover: [IConfigItemType.Ebook, IConfigItemType.QueryEbook],
  bookCover2: [IConfigItemType.Ebook, IConfigItemType.QueryEbook],
  mainBanner: [IConfigItemType.Ebook, IConfigItemType.Feed, IConfigItemType.Link, IConfigItemType.Search, IConfigItemType.EbookBundle, IConfigItemType.QueryEbookBundle],
  rank: [IConfigItemType.Ebook, IConfigItemType.QueryEbook],
  tagCloud: [IConfigItemType.Search],
  editorPick: [IConfigItemType.EditorReviewEbook],

  // linkBanner: []
  // linkBannerFullWidth: [],
  // testimonial: [],
};

const SectionBuilderFormObj: { [key in ISectionBuilderFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  note: yup.string().required().trim(),
  layoutId: yup.string().required().trim(),
  title: yup.string().required().max(40).trim(),
  target: yup.string().oneOf(['None', 'Feed', 'Link', 'User', 'Explore', 'Search']), // TODO: update type
  configItems: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().trim(),
      }),
    )
    .required(),
  contentType: yup.string().trim(),
};

export default SectionBuilderFormObj;
